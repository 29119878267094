import React from 'react'
import { CFView, CFImage, DefaultScreen, MobileScreen } from 'components'
import { foodGallery, mobileGallery } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center m="0 auto" w="100%">
          <CFImage src={mobileGallery} alt="Food Gallery" w="100%" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center maxWidth="1400px" w="100%" mt="-5px">
          <CFImage src={foodGallery} alt="Food Gallery" w="100%" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
