import React from 'react'
import { Parallax } from 'react-parallax'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, mobileAbout } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView>
          <CFImage
            w="100%"
            src={about}
            alt="Komo Sushi Japanese Restaurant hero text"
            zIndex={98}
          />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          m="0 auto"
          w="100%"
          h="600px"
          maxWidth="1400px"
          image={`url(${about}) bottom/ cover no-repeat`}
          style={{ backgroundAttachment: 'fixed' }}
        ></CFView>
      </DefaultScreen>
    </CFView>
  )
}
